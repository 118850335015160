.pagination {
  list-style: none;
  display: flex;
}

.pagination li {
  margin: 0 5px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.pagination li.active {
  background-color: #007bff;
  color: #fff;
}
